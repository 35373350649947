<template>
  <layout-landing>
    <div class="landing reset-password container">
      <header class="landing__header">
        <div class="landing__logo-container">
          <img
            alt="Logo Sayl Connect"
            class="landing__logo"
            :src="logo"
          />
        </div>
      </header>

      <div class="landing__body">
        <!-- Branding -->
        <div class="landing__branding">
          <h1 class="title">{{ $t('conn3ct-wallet.reset_password_confirmation_form_title') }}</h1>
          <div class="description">
            <p v-html="$t('conn3ct-wallet.reset_password_confirmation_form_description')"></p>
          </div>
        </div>

        <div
          class="landing__form"
          v-if="!done">
          <div
            class="landing__error"
            v-if="!$basil.isNil(error)"
          >{{ error }}</div>

          <forms-input
            class="landing__input"
            :errors="fErrors['password']"
            icon-pre="lock"
            :placeholder="$t('conn3ct-wallet.password_placeholder')"
            :type="showPassword ? 'text' : 'password'"
            @input="onRemoveError('password')"
            v-model="password">
            <template #suffix>
              <div @click="onPassword">
                <ui-icon :glyph="showPassword ? 'visible' : 'hidden'" />
              </div>
            </template>
          </forms-input>

          <forms-input
            class="landing__input"
            icon-pre="lock"
            :placeholder="$t('conn3ct-wallet.password_confirmation_placeholder')"
            :type="showPasswordConfirmation ? 'text' : 'password'"
            v-model="confirmation">
            <template #suffix>
              <div @click="onPasswordConfirmation">
                <ui-icon :glyph="showPasswordConfirmation ? 'visible' : 'hidden'" />
              </div>
            </template>
          </forms-input>

          <actions-button
            v-if="!done"
            @click="onConfirm"
            :appearance="$pepper.Appearance.PRIMARY"
            class="landing__action"
            :disabled="loading || !isFilled"
            :loading="loading"
          >{{ $t('conn3ct-wallet.reset_password_confirmation_action') }}</actions-button>
        </div>

        <div
          class="landing__done"
          v-if="done">
          <span>{{ $t('conn3ct-wallet.reset_password_confirmation_success_label') }}</span>

          <actions-button
            class="landing__action"
            icon-post="angles-right"
            @click="$router.push({ name: 'sayl-connect_landing-login' })"
            :loading="loading"
          >{{ $t('conn3ct-wallet.reset_password_confirmation_go_to_login_action') }}</actions-button>
        </div>
      </div>
    </div>
  </layout-landing>
</template>

<script>
import LayoutLanding from '@/layouts/landing.vue'
import { mapState } from 'vuex'

export default {
  name: 'SaylConn3ctRegister',

  components: {
    LayoutLanding,
  },

  data() {
    return {
      confirmation: '',
      done: false,
      error: null,
      fErrors: {},
      password: '',
      loading: false,
      showPassword: false,
      showPasswordConfirmation: false,
      token: null
    }
  },

  computed: {
    ...mapState({
      bootstrap: state => state.bootstrap,
    }),

    isFilled() {
      return [
        this.$basil.isNil(this.password),
        this.$basil.isEmpty(this.password),
        this.$basil.isNil(this.confirmation),
        this.$basil.isEmpty(this.confirmation),
      ].filter(f => f === true).length === 0
    },

    merchantLogo() {
      return this.bootstrap?.data?.brand?.logo
    },

    logo() {
      if(this.merchantLogo) {
        return this.translate(this.merchantLogo.url)
      }

      return this.$root.getSrc('statics/images/sayl-logo-color.svg')
    }
  },

  methods: {
    onBack() {
      this.$router.push({ name: 'sayl-connect_landing-reset-password' }).catch(() => {})
    },

    onConfirm() {
      this.loading = true
      this.errors = null
      this.fErrors = {}

      this.$user.changePassword({ confirmation: this.confirmation, password: this.password, token: this.token })
        .then(() => {
          this.done = true
          setTimeout(() => this.$router.push({ name: 'sayl-connect_landing-login' }).catch(() => {}), 5000)
        })
        .catch((e) => {
          if(this.$basil.get(e, 'status', 500) === 422) {
            let keys = Object.keys(e._items)
            keys.forEach(k => {
              let v = e._items[k].split('.').join('_')
              this.fErrors[k] = [this.$t('conn3ct-wallet.register_' + v)]
            })
          } else if(this.$basil.get(e, 'status', 500) === 404) {
            this.error = this.$t('conn3ct-wallet.reset_password_confirmation_not_found')
          }
        })
        .finally(() => this.loading = false)
    },

    onPassword() {
      this.showPassword = !this.showPassword
    },

    onPasswordConfirmation() {
      this.showPasswordConfirmation = !this.showPasswordConfirmation
    },

    onRemoveError(name) {
      delete this.fErrors[name]
    },

    onPressEnter(e) {
      if(e.keyCode === 13 && this.isFilled && !this.loading) {
        this.onConfirm()
      }
    }
  },

  mounted() {
    window.addEventListener('keypress', this.onPressEnter)
    this.token = this.$basil.get(this.$route, 'query.token')

    if(this.$basil.isNil(this.token)) {
      this.$notification({
        duration: 5000,
        title: this.$t('conn3ct-wallet.'),
        type: 'error',
      })
    }
  },

  beforeDestroy() {
    window.removeEventListener('keypress', this.onPressEnter)
  }
}
</script>
